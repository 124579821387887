/**
 * 用于智能助手页面
 */

const state = {
	previewList: [], // 预览列表
	selectedMaterial: [] // 已选列表
}

const mutations = {
	SET_DATA(state, data = {}) {
		for (let key in data) {
			state[key] = data[key]
		}
	},
	CLEAR_DATA(state) {
		state.previewList = []
		state.selectedMaterial = []
	}
}

export default {
	namespaced: true,
	state,
	mutations
}
