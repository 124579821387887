const getters = {
	token: (state) => state.user.token,
	name: (state) => state.user.token,
	info: (state) => state.user.info,
	systemInfo: (state) => state.user.systemInfo,
	strategyId: (state) => state.user.strategyId,
	showNavigation: (state) => {
		let systemInfo = state.user.systemInfo
		let res =
			(systemInfo?.userAgent == 'wxwork' || systemInfo?.userAgent == 'micromessenger') &&
			systemInfo?.deviceModel == 'PC'
		return res
	},
	previewList: (state) => state.intellectAssistant.previewList,
	selectedMaterial: (state) => state.intellectAssistant.selectedMaterial
}
export default getters
