// vuex@next https://next.vuex.vuejs.org/
// vuex-persistedstate https://github.com/robinvdvleuten/vuex-persistedstate

import { createStore } from 'vuex'

import getters from './getters'
import user from './modules/user'
import screenCustomer from './modules/screenCustomer'
import intellectAssistant from './modules/intellectAssistant'

export default createStore({
	modules: {
		user,
		screenCustomer,
		intellectAssistant
	},
	getters,
	plugins: []
})
